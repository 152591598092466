body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
	"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
	sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #0b284a !important;
  
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	 -khtml-user-select: none; /* Konqueror HTML */
	   -moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
			user-select: none; /* Non-prefixed version, currently
								  supported by Chrome, Edge, Opera and Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
	monospace;
}

.fadeIn {
	-webkit-animation: fadein 1.1s; /* Safari, Chrome and Opera > 12.1 */
	   -moz-animation: fadein 1.1s; /* Firefox < 16 */
		-ms-animation: fadein 1.1s; /* Internet Explorer */
		 -o-animation: fadein 1.1s; /* Opera < 12.1 */
			animation: fadein 1.1s;
}
.fadeIn2s {
-webkit-animation: fadein 2.1s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 2.1s; /* Firefox < 16 */
	-ms-animation: fadein 2.1s; /* Internet Explorer */
		-o-animation: fadein 2.1s; /* Opera < 12.1 */
		animation: fadein 2.1s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}




::-webkit-scrollbar {
	width: 10px;
}
 
::-webkit-scrollbar-track {
	background: #0377fc;
	
}
 
::-webkit-scrollbar-thumb {
	background: #c9711e;
    border-radius: 15px;
}



.disableSelection {
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}


.reduceOpacityOnHover:hover {
	opacity: 0.8;
}


@media screen and (max-width: 575px) {
	.hideOnSmallScreens {
		display: none !important;
	}
}



#particle-background {
	position: fixed;
	width: 100%;
	height: 100%;
	/* background-image: url(""); */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	filter: blur(6px);
	-webkit-filter: blur(6px);
	-ms-webkit-filter: blur(6px);
	-moz-webkit-filter: blur(6px);
	-khtml-webkit-filter: blur(6px);
	z-index: -10;
}

#particle-background canvas {
	width: 100%;
	height: 100%;
}


.info-box-container {
	overflow: hidden !important;
}

.info-box {
	color: black;
	font-family: Raleway, Arial;
}



.info-box-text-container.outer {
	background-color: #adb6c2;
}

.info-box:nth-child(2n+1) .info-box-title  {
	color: #0377fc
}
.info-box:nth-child(2n+0) .info-box-title {
	color: #c9711e;
}

.iconLink {
	padding: 5px;
	color: black !important;
}
.iconLink:-webkit-any-link {
	color: black !important;
}
.iconLink:hover {
	color: #204875  !important;
}


@media screen and (max-width: 575px) {
	.info-box {
		margin: 25px;
	}

	.info-box-text-container.outer {
		border-radius: 5px;
		padding: 40px;
	}
	
	.info-box-title  {
		font-size: 2em;
	}
}

@media screen and (max-width: 1300px) {
	
	.info-box-viewer {
		position: relative;
		padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */ 
		margin: 0px 0px 20px 0px;
	}
	
	.info-box-viewer iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	
	.image-slider-container {
		width: 100%;
		padding: 15px 15px 10px 15px;
		background-color: #0377fc;
		border-radius: 10px;
	}

	.image-slider {
		margin: 5px 20px 20px 20px;
	}
}


@media screen and (min-width: 575px) and (max-width: 1300px)  {
	.info-box {
		margin: 50px;
	}

	.info-box-text-container.outer {
		border-radius: 15px;
		padding: 50px;
	}

	.info-box-title  {
		font-size: 2.5em;
	}
}

@media screen and (min-width: 1300px) and (max-width: 2500px)  {
	.info-box {
		margin: 60px 100px 60px 100px;
		width: 72.5%;
	}
}



@media screen and (max-width: 2500px) {
	.info-box-container {
		width: 100%;
	}
}

@media screen and (min-width: 2500px) {
	.info-box {
		margin: 60px 100px 60px 100px;
		width: 72.5%;
	}

	.info-box-container {
		width: 2000px;
		margin: auto;
	}
}


@media screen and (min-width: 1300px) {

	.info-box {
		overflow: visible;
	}

	.info-box-text-container.outer {
		border-radius: 25px;
		padding: 75px;
	}



	.info-box:nth-child(2n+1)  {
		text-align: right;
		float: left;
	}
	.info-box:nth-child(2n+0) {
		text-align: left;
		float: right;
	}


	.info-box:nth-child(2n+1) .info-box-text-container.outer {
		-webkit-transform: skew(15deg);
		-moz-transform: skew(15deg);
		transform: skew(15deg);
	}
	.info-box:nth-child(2n+0) .info-box-text-container.outer {
		-webkit-transform: skew(-15deg);
		-moz-transform: skew(-15deg);
		transform: skew(-15deg);
	}


	.info-box:nth-child(2n+1) .info-box-text-container.inner {
		-webkit-transform: skew(-15deg);
		-moz-transform: skew(-15deg);
		transform: skew(-15deg);
	}
	.info-box:nth-child(2n+0) .info-box-text-container.inner {
		-webkit-transform: skew(15deg);
		-moz-transform: skew(15deg);
		transform: skew(15deg);
	}


	.info-box-title  {
		font-size: 3em;
	}


	.info-box-viewer-float {
		width: 0px;
		height: 0px;
	}
	
	.info-box:nth-child(2n+1) .info-box-viewer-float {
		float: right;
	}
	.info-box:nth-child(2n+0) .info-box-viewer-float {
		float: left;
	}


	.info-box-viewer {
		display: block;
		padding: 15px 15px 8px 15px;
		border-radius: 15px;
	}
	.info-box:nth-child(2n+1) .info-box-viewer {
		float: right;
		clear:right;
		margin: -0px -300px 0px 50px;
		background-color: #0377fc;
	}
	.info-box:nth-child(2n+0) .info-box-viewer {
		float: left;
		clear: left;
		margin: 0px 50px 0px -300px;
		background-color: #c9711e;
	}
	.info-box-viewer.more-space {
		margin-top: -110px !important;
	}
	
	.info-box-viewer iframe {
		width: 576px;
		height: 324px;
		/* border: #0377fc 15px solid;
		border-radius: 15px; */
	}


}





@media screen and (max-width: 1750px) {
	.info-box-image-container {
		display: none;
	}

	.info-box-images-container-relative {
		margin-top: 25px;
	}

	.image-container {
		display: none;
	}
	
	.image-slider-container img {
		max-width: 800px;
		max-height: 400px;
		padding: 0px 50px 0px 50px;
	}
}






@media screen and (min-width: 1300px) and (max-width: 1750px) {

	.info-box.has-images {
		margin-bottom: 375px;
	}
	
	.info-box-images-container-relative {
		position: relative;
		bottom: -40px;
		left: 30px;
	}


	.info-box-images-container-absolute {
		position: absolute;
	}
	
	.image-slider-container {
		position: absolute;
		top: 0;
		width: 700px;
		padding: 20px 25px 20px 25px;
		border-radius: 10px;
	}
	.info-box:nth-child(2n+1) .image-slider-container {
		background-color: #0377fc;
		-webkit-transform: skew(15deg);
		-moz-transform: skew(15deg);
		transform: skew(15deg);
	}
	.info-box:nth-child(2n+0) .image-slider-container {
		background-color: #c9711e;
		-webkit-transform: skew(-15deg);
		-moz-transform: skew(-15deg);
		transform: skew(-15deg);
	}

	.image-slider {
		margin: 5px 20px 20px 20px;
	}
	.info-box:nth-child(2n+1) .image-slider {
		-webkit-transform: skew(-15deg);
		-moz-transform: skew(-15deg);
		transform: skew(-15deg);
	}
	.info-box:nth-child(2n+0) .image-slider {
		-webkit-transform: skew(15deg);
		-moz-transform: skew(15deg);
		transform: skew(15deg);
	}
}

@media screen and (min-width: 1750px) {

	.info-box.has-images {
		margin-bottom: 425px;
	}

	.info-box-images-container-relative {
		position: relative;
		bottom: -40px;
		left: 20px;
		width: 50px;
	}

	.info-box-images-container-absolute {
		position: absolute;
	}


	.image-slider-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 600px;
		padding: 12px 12px 15px 12px;
		border-radius: 10px;
	}
	.info-box:nth-child(2n+1) .image-slider-container {
		right: -550px;
		background-color: #0377fc;
		-webkit-transform: skew(15deg);
		-moz-transform: skew(15deg);
		transform: skew(15deg);
	}
	.info-box:nth-child(2n+0) .image-slider-container {
		left: 550px;
		background-color: #c9711e;
		-webkit-transform: skew(-15deg);
		-moz-transform: skew(-15deg);
		transform: skew(-15deg);
	}

	.image-slider {
		margin: 5px 25px 15px 25px;
	}
	.info-box:nth-child(2n+1) .image-slider {
		-webkit-transform: skew(-15deg);
		-moz-transform: skew(-15deg);
		transform: skew(-15deg);
	}
	.info-box:nth-child(2n+0) .image-slider {
		-webkit-transform: skew(15deg);
		-moz-transform: skew(15deg);
		transform: skew(15deg);
	}

	.image-slider img {
		padding: 3px;
	}
	.image-slider img.selected {
		padding: 0px;
		border: 3px #adb6c2 solid;
	}
	.image-slider img:hover {
		opacity: 0.80;
	}



	.image-container {
		position: absolute;
		top: 0;
		padding: 15px;
		border-radius: 10px;
	}
	.info-box:nth-child(2n+1) .image-container {
		left: 600px;
		background-color: #0377fc;
		-webkit-transform: skew(15deg);
		-moz-transform: skew(15deg);
		transform: skew(15deg);
	}
	.info-box:nth-child(2n+0) .image-container {
		right: -550px;
		background-color: #c9711e;
		-webkit-transform: skew(-15deg);
		-moz-transform: skew(-15deg);
		transform: skew(-15deg);
	}

	.image-container img {
		max-width: 800px;
		max-height: 400px;
		width: auto;
		height: auto;
		padding: 0px 50px 0px 50px;
	}
	.info-box:nth-child(2n+1) .image-container img {
		-webkit-transform: skew(-15deg);
		-moz-transform: skew(-15deg);
		transform: skew(-15deg);
	}
	.info-box:nth-child(2n+0) .image-container img {
		-webkit-transform: skew(15deg);
		-moz-transform: skew(15deg);
		transform: skew(15deg);
	}
}





.landing-page {
	text-align: center;	
}

.landing-page {
	color: white;
}


.introduction-text {
	margin: 60px 0px 60px 0px;
}

.introduction-text .introduction {
	max-width: 950px;
	font-size: larger;
	text-align: center;
	color: white;
	margin: auto;
}




.education-container {
	background-color: #adb6c2;
	margin: 60px 0px 80px 0px;
	padding: 30px;
}

.education-container .education {
	display: flex;
	max-width: 500px;
	flex-flow: row nowrap;
	margin: auto;
}

.education-container .education .box {
	display: flex;
	flex-flow: column nowrap;
	padding: 15px;
	border-radius: 10px;
	min-width: 175px;
	background-color: #0377fc;
	color: white;
	font-size: medium;
	font-weight: bold;
	margin: auto;
}


.links-container {
	background-color: #adb6c2;
	margin: 80px 0px 80px 0px;
	padding: 30px;
}

.links-container .links {
	display: flex;
	max-width: 800px;
	flex-flow: row nowrap;
	margin: auto;
}

.links-container .links .box {
	display: flex;
	flex-flow: column nowrap;
	background-color: #c9711e;
	padding: 15px;
	min-width: 125px;
	border-radius: 10px;
	color: white;
	font-size: medium;
	font-weight: bold;
	margin: auto;
}


.contact-list {
	width: 60%;
	margin: auto;
}

.contact-list .links {
	margin-top: 50px;
}

.contact-list .links .link {
	background-color: #adb6c2;
	margin: 50px 0px 50px 0px;
	border-radius: 25px;
}

.contact-list .links .link .icon-box {
	display: inline-block;
	padding: 30px;
	margin: 20px;
	background-color: #cad3e0;
	border-radius: 25px;
	
}

.contact-list .links .link .text {
	display: inline-block;
	font-family: Raleway, Arial;
	font-size: 2em;
	margin: 50px;
}

